import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaSpotify
} from 'react-icons/fa';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            instagram
            facebook
            twitter
            youtube
            spotify
          }
        }
      } 
    `}
    render={data => (
      <footer className="pa2 bg-dark-gray near-white pv5">
        <div className="flex flex-wrap justify-around w-100 mw9 center mb5">
          <div className="w-100 mw5 mb4">
            <hr />
            <div className="w-100 flex justify-around items-center pv2">
              {data.site.siteMetadata.facebook && (
                <a
                  className="near-white"
                  href={data.site.siteMetadata.facebook}
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <FaFacebookF aria-label="facebook"/>
                </a>
              )}

              {data.site.siteMetadata.instagram && (
                <a
                  className="near-white"
                  href={data.site.siteMetadata.instagram}
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <FaInstagram aria-label="instagram"/>
                </a>
              )}
              {data.site.siteMetadata.spotify && (
                <a className="near-white" href={data.site.siteMetadata.spotify}
                   rel="nofollow noreferrer"
                   target="_blank"
                >
                  <FaSpotify aria-label="twitter"/>
                </a>
              )}
              {data.site.siteMetadata.youtube && (
                <a className="near-white" href={data.site.siteMetadata.youtube}
                   rel="nofollow noreferrer"
                   target="_blank"
                >
                  <FaYoutube aria-label="twitter"/>
                </a>
              )}
              {data.site.siteMetadata.twitter && (
                <a className="near-white" href={data.site.siteMetadata.twitter}
                   rel="nofollow noreferrer"
                   target="_blank"
                >
                  <FaTwitter aria-label="twitter"/>
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="w-100 mw9 white center sans-serif f6">
          <p>El Último Mono (C) 2018 - 2021</p>
        </div>
      </footer>
    )} />
);

export default Footer;
