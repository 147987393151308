import React, {useState} from 'react';
import {graphql, Link, useStaticQuery} from "gatsby"
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaSpotify
} from 'react-icons/fa';
const Navbar = ({title}) => {

  const data = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  navbarLinks {
                      to
                      name
                  }
                  siteTitle: title
                  instagram
                  facebook
                  twitter
                  youtube
                  spotify
              }
          }
      }
  `);

  return (
    <>
      <div className="fixed z-999 w-100 sans-serif">
        <div className="bg-black w-100 flex justify-around items-center pv2">
          {data.site.siteMetadata.facebook && (
            <a
              className="near-white"
              href={data.site.siteMetadata.facebook}
              rel="nofollow noreferrer"
              target="_blank"
            >
              <FaFacebookF aria-label="facebook"/>
            </a>
          )}

          {data.site.siteMetadata.instagram && (
            <a
              className="near-white"
              href={data.site.siteMetadata.instagram}
              rel="nofollow noreferrer"
              target="_blank"
            >
              <FaInstagram aria-label="instagram"/>
            </a>
          )}
          {data.site.siteMetadata.spotify && (
            <a className="near-white" href={data.site.siteMetadata.spotify}
               rel="nofollow noreferrer"
               target="_blank"
            >
              <FaSpotify aria-label="twitter"/>
            </a>
          )}
          {data.site.siteMetadata.youtube && (
            <a className="near-white" href={data.site.siteMetadata.youtube}
               rel="nofollow noreferrer"
               target="_blank"
            >
              <FaYoutube aria-label="twitter"/>
            </a>
          )}
          {data.site.siteMetadata.twitter && (
            <a className="near-white" href={data.site.siteMetadata.twitter}
               rel="nofollow noreferrer"
               target="_blank"
            >
              <FaTwitter aria-label="twitter"/>
            </a>
          )}
        </div>
        <h1 className="f6 dark-gray display fw4 f6-l f2 tc dn">{title}</h1>
        <nav className="db dt-l w-100 border-box pa3 ph5-l bg-white">
          <Link to="/" title="El Último Mono" className="db dtc-l v-mid mid-gray link dim w-100 w-25-l tc tl-l mb2 mb0-l">
            <img src={`/logo.svg`} className="dib w-60"/>
          </Link>
          <div className="db dtc-l v-mid w-100 w-75-l tc tr-l">
            {data.site.siteMetadata.navbarLinks.map(({ to, name }, key) =>
              <Link
                key={key}
                to={to}
                className="link dim dark-gray f6 f5-l dib mr3 mr4-l"
              >{name}</Link>
            )}
          </div>
        </nav>
      </div>
    </>
)
}
export default Navbar;
