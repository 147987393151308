import React from 'react';
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import "./main.scss"
const Layout = ({title, children, location}) => {

return (
  <>
    <Navbar title={title}/>
    <main  className="pt6">
      {children}
    </main>
    <Footer />
  </>)
}

export default Layout;
